
.delete_modal_content {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
  }
  
  .delete_modal_body {
    padding: 20px;
  }
  
  .delete_modal_header {
    display: flex;
    justify-content: flex-end;
  }
  
  .delete_modal_footer {
    margin-top: 20px;
  }
  
  .delete_modal_footer .btn {
    font-size: 18px;
    padding: 12px;
  }
  
  @media (max-width: 767px) {
    .delete_modal_content {
      max-width: 90%;
      top: 15%;
    }
  
    .delete_modal_footer .btn {
      font-size: 16px;
      padding: 10px;
    }

    .delete-wrapper {
      font-size: 2rem;
      padding: 16px;
    }
  
    .delete_modal_body h3 {
      font-size: 1.25rem;
    }
  
    .delete_modal_body p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 500px) {
    .delete_modal_content {
      max-width: 100%;
      padding: 10px;
    }
  
    .delete_modal_footer .btn {
      font-size: 14px;
      padding: 8px;
    }
  }
  